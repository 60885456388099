#projects div {
    display: inline-grid;
    text-wrap: wrap;
    flex-wrap: wrap;
}

.project-card-container {
    align-items: start;
    flex-wrap: wrap;
  }

.project-card {
    padding: 2%;
    margin: 2% 2% auto;
    border-radius: 25px;
    opacity: 70%;
    width: 300px;
    background-color: lightyellow;
    height: 200px;
}

.project-card:hover {
    background-color: goldenrod;
    color: white;
    opacity: 80%;
}

:hover {
    opacity: 100%;
}

.project-card h1 {
    text-shadow: 1px 1px black;
}

li {
    list-style-type: none;   
}
a {
    color: black;
}

a:link {
    text-decoration: none;
    color: black;
}

