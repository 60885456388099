.App {
  text-align: center;
  font-family: "Quicksand", sans-serif;
}

.App p {
  margin: 2% 10%;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* My stuff */

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Header */
p {
  opacity: 70%;
  animation: fadeIn 4s;
}

p.visible {
  animation: fadeIn 4s;
}

nav {
  display: block;
  margin: auto;
  padding: 1%;
}

nav li {
  margin: 20px;
}

.fade-in-text.visible {
  animation: fadeIn 5s;
}

.pronunciation {
  margin: auto;
}

.socialLinks li {
  display: inline;
  padding: 3%;
  font-style: italic;
  opacity: 70%;
  color: black;
}

.socialLinks li:hover {
  opacity: 100%;
  color: blue;
}

nav li {
  display: inline;
  padding: 5px;
  opacity: 70%;
}

/* .header {
  background-image: url("../assets/me.png");
  opacity: 12%;
} */

/* Sections */

.section {
  min-height: 200px;
  padding: 1%;
}

.section h2 {
  border-radius: 10px;
  padding: 0.5% 2% 0.5%;
  display: inline-block;
}

button {
  padding: 5%;
  background-color: blue;
  color: white;
  border-radius: 10px;
  opacity: 90%;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.skill-list {
  display: flexbox;
  text-align: left;
  margin: auto 20%;
}

.skill-item {
  display: inline-flex;
  padding: 2%;
  background-color: gold;
  border-radius: 10%;
  margin: 1.5%;
}

.skill-item:hover {
  color: white;
}

/* footer */

#contact {
  /* background-color: lightblue; */
  display: inline-grid;
  margin: 1%;
  min-width: 300px;
  border-radius: 8px;
  padding: 3%;
}

#contact input {
  margin-bottom: 4%;
  padding: 2%;
  border-radius: 10px;
}

#contact textarea {
  border-radius: 10px;
  height: 100px;
}

#contact .submitButton {
  /* margin-top: 3%; */
  width: 80%;
  margin: 4% auto;
  background-color: gold;
}

footer {
  width: 100%;
  padding: 2%;
  /* margin-top: 20px; */
  font-size: 15px;
  background-color: black;
  color: white;
}

.footer-top {
  display: inline-flex;
}
